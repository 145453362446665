<template>
  <div class="bg-brand-primary/20">
    <div class="absolute inset-x-0 top-0 z-20">
      <slot />
    </div>
    <div class="relative isolate overflow-hidden pt-14 lg:px-10 px-6 lg:min-h-[50rem] flex lg:flex-col justify-end h-screen">
      <div v-if="data.media !== 'video'" class="absolute inset-0 bg-black/50 opacity-90 w-full h-full z-[1]" />
      <video
        v-if="data.media === 'video'"
        playsinline="true"
        preload="auto"
        loop="true"
        autoplay
        muted
        class="object-cover absolute left-0 w-full h-full top-0 brightness-50"
      >
        <source
          v-if="data.video.extension === 'webm'"
          type="video/webm"
          :src="data?.video?.url"
        >
        <source
          v-if="data.video.extension === 'mp4'"
          type="video/mp4"
          :src="data?.video?.url"
        >
      </video>

      <BaseImage
        v-else
        :src="data?.image?.original?.src"
        preload
        class="absolute inset-0 -z-10 h-full w-full object-cover"
        alt="Hero background"
        quality="50"
      />
      <div class="container py-16 grid grid-cols-12 z-10">
        <div class="col-span-full lg:col-span-7 flex flex-col gap-8">
          <BaseTitle
            level="h1"
            variant="white"
            class="leading-tight"
          >
            <span class="w-fit text-balance" v-html="computedTitle" />
          </BaseTitle>
          <p class="text-white text-xl text-balance">
            {{ data?.subtitle }}
          </p>
        </div>
        <div class="col-span-full lg:col-span-5 flex gap-4 flex-col justify-end lg:items-start">
          <BaseButtonLink
            variant="primary"
            class="lg:w-fit w-full"
            size="xl"
            :to="localePath(data?.main_button?.button_link)"
            @click="onClickCta"
          >
            {{ data?.main_button?.button_text }}
          </BaseButtonLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import BaseTitle from '~/components/BaseTitle.vue';
import BaseImage from '~/components/BaseImage.vue';
import BaseButtonLink from '~/components/BaseButtonLink.vue';
import { useGTM } from '~/composables';
import { TRACKING_EVENTS } from '~/lib/constants';
import { useLocalePath } from '#imports';

const props = defineProps({
  data: {
    required: true,
    type: Object,
  },
});

const localePath = useLocalePath();
const computedTitle = computed(() => {
  const title = props.data?.title || '';

  return title.replace('|', '<br>');
});

const { triggerEvent } = useGTM();
function onClickCta () {
  triggerEvent(TRACKING_EVENTS.CLICK_HERO_CTA);
}
</script>
